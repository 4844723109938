import { createContext } from 'react';

interface SocialShareModalContextProps {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  downloadImage: () => void;

  shareUrl: string;
  sharedImageUrl: string;
  isLoading: boolean;

  error?: unknown | null;
}

export const SocialShareModalContext = createContext<SocialShareModalContextProps
| undefined>(undefined);
