import React, {
  FC, ReactNode, useCallback, useMemo, useState,
} from 'react';
import toast from 'react-hot-toast';
import { SocialShareModalContext } from 'contexts/SocialShareModalContext';
import { observer } from 'mobx-react-lite';
import { useGetUserPetProfiles } from 'hooks/useGetUserPetProfiles';
import { useGetBreeds } from 'hooks/useGetBreeds';
import { useParams } from 'react-router-dom';
import { getDomain } from 'helpers/getDomain';
import { getSharedImageURL } from 'helpers/getImageURL';
import {
  MIXED_BREED_SHARE_SHEET_TEXT_ID,
  PURE_BREED_SHARE_SHEET_TEXT_ID,
} from 'constants/query/shareSheetTextQuery';

interface SocialShareModalProviderProps {
  children: ReactNode;
}

const SOMETHING_WENT_WRONG = 'Something went wrong, please reload the page and try again';

const processError = (e: unknown, message?: string) => {
  console.error(e);
  const msg = message ?? (e as any).message ?? SOMETHING_WENT_WRONG;
  toast.error(msg);
};

export const SocialShareModalProvider: FC<SocialShareModalProviderProps> = observer(({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const { testsStore } = useGetUserPetProfiles();
  const { areProfilesLoaded, generatedImage } = testsStore;
  const { breedStore } = useGetBreeds();
  const { breedResults, breedResultsLoaded } = breedStore;
  const { testId } = useParams();

  const sharedImageUrl = useMemo(
    () => (generatedImage?.message?.external_media_id
      ? getSharedImageURL(generatedImage?.message?.external_media_id)
      : ''),
    [generatedImage],
  );

  const isReady = useMemo(
    () => areProfilesLoaded && breedResultsLoaded,
    [areProfilesLoaded, breedResultsLoaded],
  );
  const shareUrl = useMemo(() => {
    const domain = getDomain();
    return `https://petdna.${domain}/share/${testId}`;
  }, [testId]);

  const openModal = useCallback(async () => {
    if (!isReady || !testId || !breedResults) {
      return;
    }

    setIsLoading(true);
    setIsOpen(true);
    setError(null);

    try {
      const image = await testsStore.generateShareImage(testId, breedResults);
      await testsStore.getShareSheetText(
        image?.message?.breeds.length === 1
          ? PURE_BREED_SHARE_SHEET_TEXT_ID
          : MIXED_BREED_SHARE_SHEET_TEXT_ID,
      );
    } catch (e) {
      setError(e);
      processError(e, SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(false);
    }

    document.body.classList.add('body-no-scroll');
  }, [breedResults, isReady, testId, testsStore]);
  const closeModal = useCallback(() => {
    setIsOpen(false);
    document.body.classList.remove('body-no-scroll');
  }, []);

  const downloadImage = useCallback(() => {
    if (!sharedImageUrl) {
      return;
    }

    fetch(sharedImageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `PET-DNA-${generatedImage?.message?.pet_name}.png`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => processError(err, SOMETHING_WENT_WRONG));
  }, [generatedImage?.message?.pet_name, sharedImageUrl]);

  const contextValue = useMemo(() => ({
    isOpen,
    isLoading: isLoading || !isReady,
    shareUrl,
    sharedImageUrl,
    openModal,
    closeModal,
    downloadImage,
    error,
  }), [
    isOpen,
    isLoading,
    isReady,
    shareUrl,
    sharedImageUrl,
    openModal,
    closeModal,
    downloadImage,
    error,
  ]);

  return (
    <SocialShareModalContext.Provider value={contextValue}>
      {children}
    </SocialShareModalContext.Provider>
  );
});
