import React from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import SiteHeader from 'components/SiteHeader/SiteHeader';
import AuthProvider from 'providers/AuthProvider';
import { SocialShareModalProvider } from 'providers/SocialShareModalProvider';
import SiteFooter from 'components/SiteFooter/SiteFooter';
import SocialSharingModal from 'components/Modals/SocialSharingModal/SocialSharingModal';
import Sourcepoint from 'config/Sourcepoint';
import { useIsFeaturedPage } from 'hooks/useIsFeaturedPage';
import { useStores } from 'stores/useStores';

const Main = observer(() => {
  useIsFeaturedPage();
  const isPartnershipsPage = useMatch('/partnerships');
  const backgroundColor = isPartnershipsPage ? '#fff' : 'transparent';
  const { sessionStore: { sessionValid } } = useStores();

  return (
    <AuthProvider>
      <SocialShareModalProvider>
        <SiteHeader />
        <main
          id="main"
          className="flex-grow-1 d-flex flex-column"
          style={{ backgroundColor }}
        >
          <Outlet />
        </main>
        <SiteFooter />
        <Sourcepoint />
        <SocialSharingModal />
      </SocialShareModalProvider>
    </AuthProvider>
  );
});

export default Main;
