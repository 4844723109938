import { getTransformationImageObj } from 'helpers/hygraphAssetTransformation';
import { getImageWithAltFragment } from 'petdna-lib-uicomponents/hygraph';

export const getSectionTraitDescriptionQuery = (
  imageWidth = 500,
  imageHeight = 500,
) => `SectionTraitDescription {
    forProject
    title {
      html
    }
    description {
      html
    }
    backgroundColor {
      hex
    }
    textColor {
      hex
    }
    imageWithAlt {
      ${getImageWithAltFragment(getTransformationImageObj(imageWidth, imageHeight))}
    }
  }`;
