import {
  action, configure, makeAutoObservable, observable,
} from 'mobx';
import apiClient from 'apiClient/index';
import {
  BreedBasicInfo, BreedInfo, BreedName, BreedResult, BreedResults,
} from 'stores/types/Breeds';
import { DeduplicationPool } from 'helpers/DeduplicationPool/DeduplicationPool';

configure({
  enforceActions: 'observed',
  isolateGlobalState: true,
  reactionRequiresObservable: true,
});

const BREED_COLORS: string[] = [
  '#C1FF3F', '#FF9B85', '#3DE8E8', '#DEBBDF',
  '#DBAB8C', '#FFE2C7', '#B7EFFB', '#FF6261',
  '#E9FEB2', '#ECE9E4', '#9286B3', '#DFFF5E',
  '#65FFFF', '#FBD7FC',
];

const EXCLUDED_COMBINED_BREEDS: string[] = ['wolf_spitz'];
const COMBINED_BREEDS: string[] = ['coyote', 'wolf', 'village_dog'];

const getBreedId = (breedId: string) => {
  if (EXCLUDED_COMBINED_BREEDS.includes(breedId)) return breedId;
  return COMBINED_BREEDS.find((breed) => breedId.startsWith(breed)) || breedId;
};

class BreedStore {
  breeds: any[] | null = null;
  breedInfo = new Map<string, BreedInfo>();
  breedNames = new Map<string, string>();
  breedsBasicInfo = new Map<string, BreedBasicInfo>();
  breedResults: BreedResults | null = null;
  breedResultsLoaded = false;
  allBreedsInfo: BreedInfo[] | null = null;
  loadingBreeds = false;
  loadingBreedNames = false;
  loadedBreedNames = false;

  deduplicationPool = new DeduplicationPool();

  constructor() {
    makeAutoObservable(this, {
      breedInfo: observable,
      breedNames: observable,
      breedsBasicInfo: observable,
      breedResults: observable,
      breedResultsLoaded: observable,
      allBreedsInfo: observable,
      breeds: observable,
      loadingBreeds: observable,
      loadingBreedNames: observable,
      loadedBreedNames: observable,
      getBreedResults: action,
      getBreeds: action,
      getBreedBasicInfo: action,
      getAllBreedNames: action,
      getBreedInfo: action,
      getAllBreedsInfo: action,
      addBreedNames: action,
      addBreedBasicInfo: action,
    });
  }

  getBreedResults = async (testId: string) => {
    await this.deduplicationPool.runTask(
      testId,
      async () => {
        this.breedResultsLoaded = false;
        const breedsResponse = await apiClient.breeds.getBreedResults(testId);
        if (breedsResponse?.data) {
          const breedIds = breedsResponse.data.breeds.map((b) => b.id);
          await this.getBreedBasicInfo(breedIds);

          const results = this.padPercentages(breedsResponse.data.breeds);
          this.breedResults = this.createBreedResults(results);
        }
        this.breedResultsLoaded = true;
      },
    );
  };

  // eslint-disable-next-line class-methods-use-this
  private padPercentages = (breeds: BreedResult[]) => {
    if (!breeds || breeds.length === 0) {
      return { breeds: [] };
    }

    const sum = breeds
      .reduce((tot, val) => tot + Math.floor(val.percentage), 0);
    const delta = 100 - sum;

    if (delta > 0) {
      const remainderRounded = [...breeds]
        .sort((a, b) => (b.percentage % 1) - (a.percentage % 1))
        .map((breed) => ({ ...breed, percentage: Math.floor(breed.percentage) }));
      for (let i = 0; i < delta; i += 1) {
        remainderRounded[i % remainderRounded.length].percentage += 1;
      }

      return { breeds: remainderRounded };
    }

    const remainderRounded = [...breeds]
      .sort((a, b) => b.percentage - a.percentage)
      .map((breed) => ({ ...breed, percentage: Math.floor(breed.percentage) }));
    for (let i = 0; i < -delta; i += 1) {
      remainderRounded[remainderRounded.length - ((i + 1) % (remainderRounded.length + 1))]
        .percentage -= 1;
    }

    return { breeds: remainderRounded };
  };

  private createBreedResults = (breeds: BreedResults) => ({
    breeds: breeds.breeds
      .map((b, i) => {
        const bId = getBreedId(b.id);
        const info = this.breedsBasicInfo.get(b.id);
        return {
          id: info ? bId : 'other',
          percentage: b.percentage,
          name: info?.name || 'other',
          color: '',
          textColor: '#000',
          overview: info?.overview,
          originCountryCode: info?.originCountryCode,
          photo: info?.photo,
          showDetails: info?.showDetails || false,
        };
      })
      .reduce((allBreeds: BreedResult[], breed: BreedResult) => {
        const currBreed = allBreeds.find((b) => b.id === breed.id);
        if (currBreed) {
          currBreed.percentage += breed.percentage;
        } else {
          allBreeds.push(breed);
        }
        return allBreeds;
      }, [])
      .sort((a, b) => {
        if (a.id === 'other') {
          return 1;
        }
        if (b.id === 'other') {
          return -1;
        }
        if (a.percentage !== b.percentage) {
          return b.percentage - a.percentage;
        }
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      })
      .map((b, i) => ({
        ...b,
        color: BREED_COLORS[i % BREED_COLORS.length],
        textColor: '#000',
      })),
  });

  getAllBreedNames = async (skip = 0) => {
    if (!this.loadedBreedNames) {
      this.loadingBreedNames = true;
      const breedsResponse = await apiClient.breeds.getAllBreedNames(skip);
      if (breedsResponse?.data && breedsResponse.data.data) {
        this.addBreedNames(breedsResponse.data.data.breeds);
        const numLoaded = breedsResponse.data.data.breeds.length + skip;
        const totalBreeds = breedsResponse.data.data.breedsConnection.aggregate.count;
        if (numLoaded < totalBreeds) {
          await this.getAllBreedNames(numLoaded);
        } else {
          this.loadedBreedNames = true;
        }
      }
    }
  };

  getBreedBasicInfo = async (breedIDs: string[]) => {
    const newBreedIds = this.combineBreedIds(breedIDs || [])
      .filter((id) => !this.breedsBasicInfo.has(id));
    if (newBreedIds.length > 0) {
      const breedsResponse = await apiClient.breeds.getBreedsList(newBreedIds);
      if (breedsResponse?.data && breedsResponse.data.data) {
        this.addBreedBasicInfo(breedsResponse.data.data.breeds);
      }
    }
  };

  getAllBreedsInfo = async () => {
    const breedsResponse = await apiClient.breeds.getAllBreedsInfo();
    if (breedsResponse?.data) {
      this.allBreedsInfo = breedsResponse.data;
    }
  };

  getBreedInfo = async (breedId: string) => {
    const bId = getBreedId(breedId);
    if (this.breedInfo.has(bId)) {
      return this.breedInfo.get(bId);
    }
    const breedResponse = await apiClient.breeds.getBreedInfo(bId);
    if (breedResponse?.data
        && breedResponse.data.data
        && breedResponse.data.data.breeds
        && breedResponse.data.data.breeds.length > 0) {
      this.breedInfo.set(bId, breedResponse.data.data.breeds[0]);
      return breedResponse.data.data.breeds[0];
    }
    return null;
  };

  getBreeds = async (testId: string) => {
    this.loadingBreeds = true;
    // TODO load breeds
    this.loadingBreeds = false;
  };

  addBreedNames = (names: BreedName[]) => {
    const tempNames = this.breedNames;
    names.forEach((breedName) => {
      if (!tempNames.has(breedName.breedID)) {
        tempNames.set(breedName.breedID, breedName.name);
      }
    });
    this.breedNames = tempNames;
  };

  addBreedBasicInfo = (breedsInfo: BreedBasicInfo[]) => {
    const tempInfo = this.breedsBasicInfo;
    breedsInfo.forEach((info) => {
      if (!tempInfo.has(info.breedID)) {
        tempInfo.set(info.breedID, info);
      }
    });
    this.breedsBasicInfo = tempInfo;
  };

  // eslint-disable-next-line class-methods-use-this
  private combineBreedIds = (breedIDs: string[]) => Array.from(
    new Set(
      (breedIDs || []).map((breedId) => getBreedId(breedId)),
    ),
  );
}

export default BreedStore;
