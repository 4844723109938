export const getSectionTextIconQuery = () => `SectionTextIcon {
    forProject
    title {
      html
    }
    description {
      html
    }
    backgroundColor {
      hex
    }
    textColor {
      hex
    }
    icon {
      url 
    }
  }`;
