import { getTransformationImageObj } from 'helpers/hygraphAssetTransformation';
import { getImageWithAltFragment } from 'petdna-lib-uicomponents/hygraph';

export const getSectionTextAndImageQuery = (width = 500, height = 500) => `
    SectionTextAndImage {
      id
      __typename
      backgroundColor {
        hex
      }
      buttonAction
      externalLink
      buttonColor {
        hex
      }
      bulletLabel
      bullets
      buttonText
      buttonTextColor {
        hex
      }
      buttonCssClass
      imageWithAlt {
        ${getImageWithAltFragment(getTransformationImageObj(width, height))}
      }
      name
      text {
        html
      }
      sectionType
      textColor {
        hex
      }
      titleColor {
        hex
      }
      title {
        html
      }
      type
    }`;

export const SECTION_TEXT_AND_IMAGE_QUERY = getSectionTextAndImageQuery();
