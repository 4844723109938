import React, {
  useCallback,
  useContext,
  useRef,
} from 'react';
import { SocialShareModalContext } from 'contexts/SocialShareModalContext';
import toast from 'react-hot-toast';
import downloadIcon from 'assets/svgs/download.svg';
import linkIcon from 'assets/svgs/link.svg';
import mailSent from 'assets/svgs/mail-sent.svg';

import './SocialSharingModal.scss';
import TextBox from 'components/Modals/SocialSharingModal/TextBox';
import SocialSharingButtonsList from 'components/Button/SocialShareButtonsList/SocialShareButtonsList';
import SocialSharingCarousel from 'components/Modals/SocialSharingModal/SocialSharingCarousel';
import { getSharedImageURL } from 'helpers/getImageURL';
import { observer } from 'mobx-react-lite';
import NotFound from 'components/NotFound/NotFound';
import { useStores } from 'stores/useStores';
import WebShare from '../WebShare/WebShare';

const IconButton: React.FC<{
  iconSrc: string;
  text: string;
  action?: any;
  children?: React.ReactNode
}> = (
  {
    iconSrc, text, action, children,
  },
) => (
  <div className="icon-button-wrapper">
    <button
      type="button"
      className="btn btn-info round-6 btn-rounded-icon"
      onClick={action}
    >
      <img height="20" width="20" src={iconSrc} alt={text} />
    </button>
    <p className="ms-1 icon-button-text">{text}</p>
    {children}
  </div>
);

const SocialSharingModal: React.FC = observer(() => {
  const context = useContext(SocialShareModalContext);

  const modalRef = useRef<HTMLDivElement>(null);
  const { testsStore, breedStore } = useStores();
  const { breedResults } = breedStore;

  const { generatedImage, shareSheetText, petProfile } = testsStore;

  if (!context) {
    throw new Error('SocialShareModal must be used within a SocialShareModalProvider!.');
  }

  const {
    isOpen, closeModal, shareUrl, sharedImageUrl, downloadImage, error,
  } = context;

  const handleClickOutside = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (modalRef.current === e.target) {
      closeModal();
    }
  }, [closeModal]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  }, [closeModal]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl)
      .then(() => {
        toast.success('Image URL copied to clipboard!');
      })
      .catch((err) => {
        toast.error('Failed to copy Image URL to clipboard!');
      });
  };

  if (!isOpen) return null;

  return (
    <div
      className="modal-social-share show d-block"
      tabIndex={-1}
      onClick={handleClickOutside}
      onKeyDown={handleKeyDown}
      ref={modalRef}
      role="presentation"
    >
      {sharedImageUrl ? (
        <>
          <div className="modal-social-share-dialog custom-modal-style ">
            <div className="modal-social-share-content ">
              <div className="modal-social-share-header p-0">
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <button type="button" className="btn-close" onClick={closeModal}>X</button>
                </div>
              </div>
              <div className="modal-social-share-body">
                <div className="container-fluid">
                  <div className="row" style={{ marginTop: '-20px' }}>
                    <SocialSharingCarousel
                      imgUrl={getSharedImageURL(generatedImage?.message?.external_media_id)}
                    />
                    <div className="col-lg-6 col-md-6  col-sm-12 ">
                      <div className="row pl-4">
                        <div className="col-12">
                          <h3 className="h1 d-none d-md-block ">{shareSheetText?.title}</h3>
                        </div>
                        <div className="col-lg-12 d-none d-md-block ">
                          <IconButton iconSrc={downloadIcon} text="Save image to device" action={downloadImage} />
                          <IconButton iconSrc={linkIcon} text="Copy Link" action={handleCopyLink} />
                          <IconButton iconSrc={mailSent} text="Share To...">
                            <SocialSharingButtonsList shareUrl={shareUrl} />
                          </IconButton>
                        </div>
                        <div className="col-12 mt-12 ">
                          <TextBox
                            content={shareSheetText?.text?.text}
                            breedResults={breedResults}
                            petName={petProfile?.name}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-12 d-sm-block d-md-none social_share_modal_btn_container">
            <div className="row">
              <div className="col d-flex justify-content-center align-items-center flex-column">
                <button
                  type="button"
                  onClick={downloadImage}
                  className="btn btn-info round-6 btn-rounded-icon"
                >
                  <img height="20" width="20" src={downloadIcon} alt="downloadIcon" />
                </button>
                <p className="ms-1">Download</p>
              </div>
              <div className="col d-flex justify-content-center align-items-center flex-column">
                <WebShare url={shareUrl} />
              </div>
              <div className="col d-flex justify-content-center align-items-center flex-column">
                <button
                  type="button"
                  onClick={handleCopyLink}
                  className="btn btn-info round-6 btn-rounded-icon"
                >
                  <img height="20" width="20" src={mailSent} alt="linkIcon" />
                </button>
                <p className="ms-1">Copy Link</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="modal-social-share-dialog custom-modal-style ">
          <div className="modal-social-share-content">
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '400px',
            }}
            >
              {
                !error ? (
                  <>
                    <span className="spinner-border spinner-border-lg  " aria-hidden="true" />
                    <span className="mt-12" role="status">Generating image....</span>
                  </>
                ) : (
                  <NotFound />
                )
              }

            </div>
          </div>
        </div>
      )}

    </div>
  );
});

export default SocialSharingModal;
