export const SECTION_TEXT_AND_TITLE_QUERY = `SectionTextAndTitle {
  id
  __typename
  name
  forProject
  title {
    html
  }
  text {
    html
  }
  titleColor {
    hex
  }
  textColor {
    hex
  }
  backgroundColor {
    hex
  }
  sectionClassNames
  titleClassNames
  textClassNames
  titleFontSize
}`;
