import { getTransformationImageObj, ITransformation, urlWithTransformation } from 'helpers/hygraphAssetTransformation';
import { getImageWithAltFragment } from 'petdna-lib-uicomponents/hygraph';

export const getImageQuery = (imageTransformationOptions: ITransformation, key: string = 'image') => {
  const url = urlWithTransformation(imageTransformationOptions);
  return `
    ${key} {
        width
        height
        ${url}
    }
  `;
};

export const getMetadataQuery = (key: string = 'seo') => (`
    ${key} {
    canonicalLinkTag
    metaDataScreenName
    metaDescription
    openGraphDescription
    openGraphImage
    openGraphTitle
    openGraphType
    openGraphUrl
    title
    hiddenH1 {
      html
    }
  }
`);

export const getPartnershipsQuery = () => (`
partnerships(locales: $locale) {
  id
  name
  locale
  partnerLogo {
    ${getImageWithAltFragment({})}
  }
  imageWithAlt {
    ${getImageWithAltFragment(getTransformationImageObj(380, 256))}
  }
  labelIcon
  labelText
  discountText
  title {
    html
  }
  text {
    html
  }
  linkText
  externalLink
  titleColor {
    hex
  }
  textColor {
    hex
  }
  labelColor {
    hex
  }
  discountBackgroundColor {
    hex
  }
  discountTextColor {
    hex
  }
}
`);

export const getImageWithCaptionQuery = (imageTransformationOptions: ITransformation, key: string = 'imageWithCaption') => (`
      ${key} {
        name
        id
        textColor {
          hex
        }
        titleColor {
          hex
        }
        imageWithAlt {
          ${getImageWithAltFragment(imageTransformationOptions)}
        }
        imagePlacement
        backgroundColor {
          hex
        }
        subText {
          html
        }
        text {
          html
        }
        title {
          html
        }
        sortOrder
      }
`);
