import { HYGRAPH_TOP_NAV_ID } from 'constants/hygraphShemeId';
import { getLanguageSwitcherOptionFragment } from 'petdna-lib-uicomponents/hygraph';

export const GET_NAV_BAR_QUERY = `query TopNav($id: ID = "${HYGRAPH_TOP_NAV_ID}") {
  topNav(where: { id: $id }) {
    name
    leftMenuItems {
      id
      name
      icon
      label
      link
      button
      cssClass
      externalLink
    }
    siteLogo {
      fileName
      url
    }
    languageSwitcher {
      languageSwitcherOptions {
        ${getLanguageSwitcherOptionFragment}
      }
    }
  }
}
`;
