export const SECTION_OPEN_URL_QUERY = `SectionOpenUrl {
    forProject
    title {
      html
    }
    description {
      html
    }
    buttonText
    url
    redirectType
    backgroundColor {
      hex
    }
    textColor {
      hex
    }
    buttonColor {
      hex
    }
    buttonTextColor {
      hex
    }
  }`;
